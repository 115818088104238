<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>社区管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path:'/postManage'}">帖子管理</el-breadcrumb-item>
      <el-breadcrumb-item>发布帖子</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="form" label-width="120px" style="text-align: left;">
      <el-form-item label="标题" placeholder="请输入标题">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="内容" placeholder="请输入内容">
        <el-input type="textarea" :rows="5" maxlength="500" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item label="选择板块">
        <el-select v-model="form.first" placeholder="请选择一级板块" @change="change" style="margin-right: 30px;">
          <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="form.second" placeholder="请选择二级板块" @change="change2">
          <el-option v-for="item in options1" :key="item.value" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否推荐到首页">
        <el-radio v-model="radio" label="1">是</el-radio>
        <el-radio v-model="radio" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="上传图片">
        <el-upload class="avatar-uploader" action="    " list-type="picture-card" show-file-list :file-list="picList" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :http-request="upload" :on-remove="picRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传视频">
        <el-upload style="width: 40%;" class="upload-demo" action="   " accept="video/*" :limit="1" :file-list="vedioList" :on-success="vediohandleAvatarSuccess" :before-upload="vediobeforeAvatarUpload" :http-request="vedioupload" :on-remove="vedioRemove" :on-exceed="handleExceed">
          <!-- :on-exceed  -->
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传mp4文件，且不超过40M</div>
        </el-upload>
        <!-- <video :src="videoSrc" v-if="videoSrc != ''" class="vedio" controls></video> -->
      </el-form-item>
      <el-form-item style="text-align: center;">
        <el-button type="primary" @click="onSubmit">发布</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
const qiniu = require("qiniu-js");
export default {
  data() {
    return {
      form: {
        title: "",
        content: "",
        first: "",
        second: "",
      },
      vedioList: [],
      picList: [],
      imageUrl: "",
      options: [],
      options1: [],
      videoSrc: "",
      cust_id: "",
      authorname: "",
      filelist: [],
      radio: "0",
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getbk();
    this.cust_id = sessionStorage.getItem("cust_id");
    this.authorname = sessionStorage.getItem("name");
    if (this.id) {
      this.getdetail();
    }
  },
  methods: {
    getdetail() {
      this.picList = [];
      this.axios
        .get("/bbs/get_gu_bbs_post_by_id?id=" + this.id + "&audit=" + 0)
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res);
            let _res = res.data.result;
            this.form.title = _res.title;
            this.form.content = _res.content;
            for (let x = 0; x < this.options.length; x++) {
              if (this.options[x].id == _res.forum_id) {
                this.form.first = this.options[x].id;
              }
            }
            this.form.second = _res.forum_id;
            if (_res.is_homepage) {
              this.radio = _res.is_homepage.toString();
            }
            if (_res.imgs.length > 0) {
              let arr = JSON.parse(_res.imgs);
              this.filelist = arr;
              for (let i = 0; i < arr.length; i++) {
                const element = arr[i];
                this.picList.push({ url: element, name: i });
              }
            }
            if (_res.video != "") {
              this.vedioList.push({ url: _res.video, name: "video" });
              this.videoSrc = _res.video;
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getbk() {
      this.axios
        .get("/bbs/get_gu_bbs_forum?forum_id=" + 0)
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.options = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    change(value) {
      this.options1 = [];
      this.form.second = "";
      this.axios
        .get("/bbs/get_gu_bbs_forum?forum_id=" + value)
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.options1 = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    change2(value) {},
    upload(content) {
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      this.axios
        .post(
          "http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php",
          formData
        )
        .then((rs) => {
          // console.log(rs);
          if (rs.data.code == 0) {
            this.picList.push({ uid: content.file.uid, url: rs.data.file });
            this.filelist.push(rs.data.file);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      let _this = this;
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 4MB!");
      }
      return isLt2M; //&& isSize
    },
    picRemove(file, fileList) {
      for (var i in this.picList) {
        for (var k in this.picList[i]) {
          if (this.picList[i][k] == file.uid) {
            this.picList.splice(i, 1);
            break;
          }
        }
      }
      // console.log(this.picList);
    },
    vedioRemove(file, filelist) {
      this.videoSrc = "";
    },
    vedioupload(file) {
      console.log(file);
      let _this = this;
      file.status = "uploading";
      file.message = "上传中...";
      let files = file.file;
      let key = new Date().getTime() + "." + files.type.split("/")[1]; // 设置图片的名字，生产随机uid，避免重复调用名字重复
      let config = {
        useCdnDomain: true, //cdn加速
        retryCount: 3, // 上传错误重新上传次数
      };
      let putExtra = {
        fname: file,
        params: {},
        mimeType: ["video/mp4"], //可以上传的type
      };
      let observer = {
        next(res) {
          // res.total.percent 上传进度
          file.message = parseInt(res.total.percent) + "%";
        },
        error(code, message, isRequestError) {
          //错误信息
          console.log(code);
          console.log(message);
          console.log(isRequestError);
        },
        complete: (res) => {
          //上传成功
          // console.log(res)
          let str = "http://cdn.51xk.info/" + res.key;
          file.status = "";
          file.message = "";
          this.vedioList.push({ url: str, name: files.name });
          this.videoSrc = str;
        },
      };
      //获取token
      this.axios
        .get("http://192.168.11.253:8080/qiniu/examples/upload_tokens.php")
        .then((res) => {
          // console.log(res);
          // this.token = res.data;
          let observable = qiniu.upload(files, key, res.data, putExtra, config); //调用七牛的上传
          let subscription = observable.subscribe(observer); //上传监听
          // console.log(qiniu)
        });
    },
    vediohandleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    vediobeforeAvatarUpload(file) {
      let _this = this;
      const isLt2M = file.size / 1024 / 1024 < 40;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 40MB!");
      }
      return isLt2M; //&& isSize
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传 1 个视频文件，您已经上传了 1 个文件`);
    },
    cancel() {
      this.$router.push({ path: "/postManage" });
    },
    onSubmit() {
      if (this.id != "") {
        console.log(this.picList);
        this.filelist = [];
        for (let m = 0; m < this.picList.length; m++) {
          const element = this.picList[m];
          this.filelist.push(element.url);
        }
        let form = {
          title: this.form.title,
          content: this.form.content,
          forum_id: this.form.second,
          img: JSON.stringify(this.filelist), //
          img_count: this.filelist.length,
          video: this.videoSrc,
          video_img: "",
          is_homepage: this.radio, //1推荐，0不推荐
          id: this.id,
        };
        // console.log(form);
        this.axios
          .post("/bbs/update_gu_bbs_post", this.qs.stringify(form))
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              this.$router.push({ path: "/postManage" });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
      } else {
        let form = {
          title: this.form.title,
          content: this.form.content,
          author_id: this.cust_id,
          author_name: this.authorname,
          sex: "",
          headimg: "",
          is_moderator: "",
          forum_id: this.form.second,
          img: JSON.stringify(this.filelist), //
          img_count: this.filelist.length,
          video: this.videoSrc,
          video_img: "",
          is_homepage: this.radio, //1推荐，0不推荐
        };
        // console.log(form);
        this.axios
          .post("/bbs/insert_gu_bbs_post", this.qs.stringify(form))
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              this.$router.push({ path: "/postManage" });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
      }
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.avatar-uploader {
  float: left;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.vedio {
  max-height: 500px;
}
</style>